<template>
  <b-card
    :img-src="require('@/assets/images/avatars/banner-12.jpg')"
    img-alt="Profile Cover Photo"
    img-top
    class="card-profile"
  >
    
    <div class="profile-image-wrapper">
      <div class="profile-image p-0">
        <b-avatar
          size="114"
          variant="light"
          :src="require('@/assets/images/avatars/5.png')"
        />
      </div>
    </div>
    <h3>{{userInfo.name}}</h3>
    <h6 class="text-muted">
      {{userInfo.email}}
    </h6>
    <h6 class="text-muted">
      {{userInfo.email}}
    </h6>
    <hr class="mb-2">

    <!-- follower projects rank -->
    <div class="d-flex justify-content-between align-items-center">
      <div>
        <h6 class="text-muted font-weight-bolder">
          Balance
        </h6>
        <h3 class="mb-0">
          {{Number(userInfo.balance.current).toLocaleString('en-US', { style: 'currency', currency: 'VND' })}}
        </h3>
      </div>
      <div class="text-center">
        <h6 class="text-muted font-weight-bolder">
          Balance
        </h6>
        <h3 class="mb-0">
          {{Number(userInfo.balance.total).toLocaleString('en-US', { style: 'currency', currency: 'VND' })}}
        </h3>
      </div>
      <!-- <div>
        <h6 class="text-muted font-weight-bolder">
          Total
        </h6>
        <h3 class="mb-1">
           {{Number(userInfo.balance.total).toLocaleString('en-US', { style: 'currency', currency: 'VND' })}}
        </h3>
      </div> -->
    </div>
    <!--/ follower projects rank -->
  </b-card>
</template>

<script>
import { BCard, BAvatar, BBadge } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BBadge,
    BAvatar,
  },
  data(){
    return{
      userInfo: this.$store.getters['auth/userInfo']
    }
  },
}
</script>
