<template>
  <b-overlay
    :show="show"
    rounded="lg"
    variant="primary"
    opacity="0.90"
    blur="2px"
  >
    <b-card no-body>
      <b-card-header>
        <h5 class="mb-0">
          DATA CAPACITY STATS
        </h5>
      </b-card-header>

      <!-- apex chart -->
      <vue-apex-charts
        type="radialBar"
        height="350"
        :options="goalOverviewRadialBar.chartOptions"
        :series="goalOverviewRadialBar.series"
      />
      <br>
      <h3 class="text-center">{{formatBytes(this.bandwith.total-this.bandwith.used)}} remaining.</h3>
      <b-link class="ht-btn ht-btn--outline hire-btn d-none d-xl-block text-center" :to="{name: 'order'}">Buy Extra BandWidth</b-link>
      <br>
      <b-row class="text-center mx-0">
        <b-col
          cols="6"
          class="border-top border-right d-flex align-items-between flex-column py-1"
        >
          <b-card-text class="text-muted mb-0">
            Total Capacity
          </b-card-text>
          <h3 class="font-weight-bolder mb-50">
            {{formatBytes(this.bandwith.total)}}
          </h3>
        </b-col>

        <b-col
          cols="6"
          class="border-top d-flex align-items-between flex-column py-1"
        >
          <b-card-text class="text-muted mb-0">
              Consumed
          </b-card-text>
          <h3 class="font-weight-bolder mb-0">
            {{formatBytes(this.bandwith.used)}}
          </h3>
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BCard, BCardHeader, BRow, BCol, BCardText, BLink, BOverlay
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

import RepositoryFactory from '../../../api/RepositoryFactory'
const userInfo = RepositoryFactory.get('user')



export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BRow,
    BCardText,
    BCol,
    BLink,
    BOverlay
  },

  data() {
    return {
      show: true,
      bandwith:{
        total:1000000000,
        used:0
      },
      goal_overview: {},
      goalOverviewRadialBar: {
        series: [0],
        labels: ['Data Used'],
        chartOptions: {
          chart: {
            sparkline: {
              enabled: true,
            },
            dropShadow: {
              enabled: true,
              blur: 3,
              left: 1,
              top: 1,
              opacity: 0.1,
            },
          },
          colors: ['#51e5a8'],
          plotOptions: {
            radialBar: {
              offsetY: -10,
              startAngle: -150,
              endAngle: 150,
              hollow: {
                size: '77%',
              },
              track: {
                background: '#ebe9f1',
                strokeWidth: '70%',
              },
              dataLabels: {
                name: {
                  show: false,
                },
                value: {
                  color: '#5e5873',
                  fontSize: '2.86rem',
                  fontWeight: '600',
                },
              },
            },
          },
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'horizontal',
              shadeIntensity: 0.5,
              gradientToColors: [$themeColors.success],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100],
            },
          },
          stroke: {
            lineCap: 'round',
          },
          grid: {
            padding: {
              bottom: 30,
            },
          },
        },
      },
    }
  },
  created() {
    //this.$http.get('/card/card-analytics/goal-overview').then(res => { this.goal_overview = res.data })
    this.getBW()
  },

  methods:{
    getBW(){
      userInfo.getBW().then(rs=>{
        this.bandwith = rs.data.bandwidth
        let used_percent = (this.bandwith.used/this.bandwith.total*100).toFixed(2)
        if(!isNaN(used_percent)){
          this.goalOverviewRadialBar.series = [used_percent]
        }else{
          this.goalOverviewRadialBar.series = [0]
        }
        
        this.show = false
      })
    },
    formatBytes(bytes) {
      var marker = 1000; // Change to 1000 if required
      var decimal = 2; // Change as required
      var kiloBytes = marker; // One Kilobyte is 1024 bytes
      var megaBytes = marker * marker; // One MB is 1024 KB
      var gigaBytes = marker * marker * marker; // One GB is 1024 MB
      var teraBytes = marker * marker * marker * marker; // One TB is 1024 GB
      // return bytes if less than a KB
      if(bytes < kiloBytes) return bytes + " Bytes";
      // return KB if less than a MB
      else if(bytes < megaBytes) return(bytes / kiloBytes).toFixed(decimal) + " KB";
      // return MB if less than a GB  ``
      else if(bytes < gigaBytes) return(bytes / megaBytes).toFixed(decimal) + " MB";
      // return GB if less than a TB
      else if(bytes < teraBytes) return (bytes / gigaBytes).toFixed(decimal) + " GB";
      
      else return (bytes / teraBytes).toFixed(decimal) + " TB";
    }
  }
}
</script>
