<template>
  <section id="dashboard-ecommerce" style="max-width: 100rem; margin: auto;">
    <b-row class="match-height"> 
      <b-col
        xl="12"
        md="12"
      >
        <b-row class="match-height justify-content-md-center">
          <b-col
            lg="4"
            md="4"
            sm="12"
          >
            <statistic-card-horizontal
              icon="UserIcon"
              :statistic="ports.use+ ' Port Active'"
              :statistic-title="ports.not_use +' Port Not Use'"
            />
          </b-col>
          <b-col
            lg="4"
            md="4"
            sm="12"
          >
            <statistic-card-horizontal
              icon="ActivityIcon"
              color="danger"
              :statistic="formatBytes(plans.current) + ' Bandwidth available'"
              :statistic-title="formatBytes(plans.next) + ' next'"
            />
          </b-col>
          <b-col
            lg="4"
            md="4"
            sm="12"
          >
            <statistic-card-horizontal
              icon="DollarSignIcon"
              color="success"
              :statistic="'Balance: '+ Number(userInfo.balance.current).toLocaleString('en-US', { style: 'currency', currency: 'USD' })"
              :statistic-title="'1$ = '+Number(settings.dollar_to_vnd).toLocaleString('en-US', { style: 'currency', currency: 'VND' })"
              button="Add Funds"
            ></statistic-card-horizontal>
          </b-col>

        </b-row>
      </b-col>
    </b-row>
    <b-row class="match-height justify-content-md-center">
      <b-col
          xl="10"
          md="10" 
        >
          <b-alert
              variant="danger"
              :show="userInfo.bandwidth.remainning != null"
            >
              <div class="alert-body">
                Your proxy disbaled because your bandwidth lower than 0 : {{ formatBytes(userInfo.bandwidth.remainning) }}
              </div>
            </b-alert>
      </b-col>
      <b-col
          xl="10"
          md="10" 
        >
          <div v-for="alert in alerts" v-bind:key="alert._id">
            <b-alert
              :variant="alert.variant"
              show
            >
              <div class="alert-body">
                <span><strong>{{alert.title}}</strong> <div v-html="alert.message"></div></span>
              </div>
            </b-alert>
          </div>
      </b-col>
    </b-row>
    <!-- <b-row class="match-height">
      <b-col
        xl="8"
        md="8"
        >
        <plans></plans>
      </b-col>
      <b-col
        xl="4"
        md="4"
        >
          <data-used></data-used>
      </b-col>
    </b-row> -->
    <!-- <b-row class="match-height justify-content-md-center">
      <b-col
          xl="10"
          md="10" 
        >
          <div v-for="alert in alerts" v-bind:key="alert._id">
            <b-alert
              :variant="alert.variant"
              show
            >
              <div class="alert-body">
                <span><strong>{{alert.title}}</strong> {{alert.message}}</span>
              </div>
            </b-alert>
          </div>
      </b-col>
    </b-row> --> 
    <!-- <b-row class="match-height">
      <b-col
        xl="8"
        md="8"
        >
        <line-data></line-data>
      </b-col> -->
      <!-- <b-col
        xl="4"
        md="4"
        >
          <data-used></data-used>
      </b-col> -->
    </b-row>
  </section>
</template>

<script>
import Activity from './Activity'
import Balance from './Balance'
import Plans from './Plans'
import Ports from './Ports'
import DataUsed from './DataUsed.vue'
import LineData from './LineData.vue'
import AccountStats from './AccountStats.vue'

import {
 BRow,BCol, BAlert, BCard, BLink
} from 'bootstrap-vue'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import Ripple from 'vue-ripple-directive'

import RepositoryFactory from '../../../api/RepositoryFactory'
import { bypassFilter } from '@vueuse/shared'
const ActivityRepository = RepositoryFactory.get('userReport')
const PlansRepository = RepositoryFactory.get('plans')

export default {
  components: {
    BAlert,
    'activity': Activity,
    'balance': Balance,
    'plans': Plans,
    'ports': Ports,
    'data-used': DataUsed,
    'line-data': LineData,
    'account-stats': AccountStats,
    BRow,BCol,
    StatisticCardHorizontal,
    Activity,
    BCard,
    BLink
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      userInfo: this.$store.getters['auth/userInfo'],
      activity: null,
      renew: 0,
      no_renew: 0,
      //plans: 0,
      plans_expired: 0,
      ports:{
        use: 0,
        not_use: 0
      },
      plans: {
        current: 0,
        next: 0
      },
      alerts: [],
      settings: {
        price_per_port: 0,
        dollar_to_vnd: 0,
        geos_avail: []
      },
    }
  },
  created(){
    // ActivityRepository.fetch().then(rs=>{
    //   this.activity = rs.data.data
    // }).catch(e=>{
    // })
    ActivityRepository.fetchAlert().then(rs=>{
      this.alerts = rs.data.data
      // console.log(this.alerts)
      // this.alerts = [
      //   {
      //     show: true,
      //     title: 'cc',
      //     variant: "success",
      //     _id: '1',
      //     message: 'dasdsa <h1>asdsa</h1>'
      //   }
      // ]
      this.settings = this.$store.getters['auth/settings']
      this.ports =  this.$store.getters['auth/ports']
      this.plans =  this.$store.getters['auth/plans']
      //console.log(this.ports)
    })

    
  },
  methods:{
    show(){
      this.$bvModal.show('modal-lg')
      
    },
    updatePlan(count){
      this.plans = count
    },
    updatePlanEx(count){
      this.plans_expired = count
    },
    updateRenew(item){
      this.renew =  item.filter(x=>x.renew == true).length
      this.no_renew = item.filter(x=>x.renew == false).length
    },
    formatBytes(bytes) {
        var marker = 1000; // Change to 1000 if required
        var decimal = 2; // Change as required
        var kiloBytes = marker; // One Kilobyte is 1024 bytes
        var megaBytes = marker * marker; // One MB is 1024 KB
        var gigaBytes = marker * marker * marker; // One GB is 1024 MB
        var teraBytes = marker * marker * marker * marker; // One TB is 1024 GB
        // return bytes if less than a KB
      if(bytes > 0){
        if(bytes < kiloBytes) return bytes + " Bytes";
        // return KB if less than a MB
        else if(bytes < megaBytes) return (bytes / kiloBytes).toFixed(decimal) + " KB";
        // return MB if less than a GB  ``
        else if(bytes < gigaBytes) return(bytes / megaBytes).toFixed(decimal) + " MB";
        // return GB if less than a TB
        else if(bytes < teraBytes) return (bytes / gigaBytes).toFixed(decimal) + " GB";
        
        else return (bytes / teraBytes).toFixed(decimal) + " TB";
      }else{
        bytes = bytes * -1

        if(bytes < kiloBytes) return bytes*-1 + " Bytes";
        // return KB if less than a MB
        else if(bytes < megaBytes) return(bytes / kiloBytes).toFixed(decimal)*-1 + " KB";
        // return MB if less than a GB  ``
        else if(bytes < gigaBytes) return(bytes / megaBytes).toFixed(decimal)*-1 + " MB";
        // return GB if less than a TB
        else if(bytes < teraBytes) return (bytes / gigaBytes).toFixed(decimal)*-1 + " GB";
        
        else return (bytes / teraBytes).toFixed(decimal)*-1 + " TB";

      } 
    }
  }
}
</script>